import { blue } from "@ant-design/colors";
import { SearchOutlined } from "@ant-design/icons";
import ProTable, {
  ActionType,
  ProColumns,
  RequestData,
} from "@ant-design/pro-table";
import { Button, Input } from "antd";
import { ColumnType } from "antd/es/table/interface";
import qs from "query-string";
import { useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { Link, useLocation } from "react-router-dom";
import api, { url } from "../../../api";
import SNButton from "../../../components/SN/SNButton";
import SNButtonGroup from "../../../components/SN/SNButtonGroup";
import {
  showDeleteModal,
  showPatchModal,
} from "../../../components/SN/SNConfirmModal";
import SNListUsers from "../../../components/SN/SNListUsers";
import { SNMenuItemProps } from "../../../components/SN/SNMenuItem";
import SNPanel from "../../../components/SN/SNPanel";
import { CompanyStatus } from "../../../components/Status";
import { paramsToObject } from "../../../lib/locationSearch";
import path from "../../Root/routePaths";
import CompanyFilter from "../components/CompanyFilter";
import { CompanyDataApi } from "../data.t";
import Tag from "antd/es/tag";

const CompaniesList = ({ history }) => {
  const location = useLocation();
  const searchInputRef = useRef<any>();
  const listRef = useRef<ActionType>();
  const [search, setSearch] = useState<string>("");
  const [searchColumn, setSearchColumn] = useState<string>("");

  const _handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearch(selectedKeys[0]);
    setSearchColumn(dataIndex);
    listRef.current?.reload();
  };

  const _handleReset = (clearFilters) => {
    clearFilters();
    setSearch("");
    setSearchColumn("");
    listRef.current?.reload();
  };

  const _refreshList = () => {
    listRef.current?.reload();
  };

  /**
   * TODO: Render view region
   */

  const _getColumnSearchProps = (
    dataIndex: string,
    title: string
  ): Pick<
    ColumnType<CompanyDataApi>,
    | "filterDropdown"
    | "filterIcon"
    | "onFilter"
    | "onFilterDropdownVisibleChange"
    | "render"
  > => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputRef}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => _handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => _handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => _handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(typeof value === "string" && value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef.current?.select());
      }
    },
    render: (text) =>
      searchColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[search]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const _renderList = () => {
    const columns: ProColumns<CompanyDataApi>[] = [
      {
        dataIndex: "id",
        key: "id",
        title: "ID",
        width: 70,
        sorter: true,
      },
      {
        dataIndex: "company_name",
        key: "company",
        title: "Company",
        sorter: true,
        ..._getColumnSearchProps("company_name", "Company name"),
        render: (_, data: any) => {
          return (
            <Link
              to={{
                pathname: path.tenant_details.replace(":id", data.id),
              }}
            >
              {data.company_name}
            </Link>
          );
        },
      },
      {
        dataIndex: "company_id",
        key: "company_id",
        title: "Org number",
        sorter: true,
      },
      {
        dataIndex: "owner",
        key: "owner",
        title: "Owner",
        render: (value: any) => {
          return (
            <SNListUsers
              users={[
                {
                  user: value,
                  contact: {
                    email: value.username,
                  },
                },
              ]}
            />
          );
        },
      },
      // {
      //   dataIndex: "created_at",
      //   key: "created_at",
      //   title: "Created",
      //   valueType: "dateTime",
      //   sorter: true,
      // },
      {
        dataIndex: "is_active",
        key: "status",
        title: "Status",
        render: (value, data) => {
          return (
            <div>
              <CompanyStatus status={data.is_active} />
              {data.is_deleted && <Tag color="red">DELETED</Tag>}
            </div>
          );
          
        },
      },
      {
        key: "actions",
        width: 50,
        fixed: "right",
        search: false,
        render: (value: any, data: any) => {
          const actions: SNMenuItemProps[] = value.is_active
            ? [
                {
                  key: "info",
                  icon: ["fal", "info"],
                  title: "Details",
                  link: path.tenant_details.replace(":id", data.id),
                },
                {
                  key: "edit",
                  icon: ["fal", "pencil-alt"],
                  title: "Edit",
                  link: path.edit_tenant.replace(":id", data.id),
                },
                {
                  key: "divider",
                },
                {
                  key: "reject",
                  icon: ["fal", "times-square"],
                  title: "Reject",
                  onClick: () => {
                    showPatchModal({
                      url: `${url.COMPANY}${value.id}/inactivate/`,
                      title: "Reject company",
                      content: `Are you sure you want to reject`,
                      message404: "Reject company failed",
                      name: data.company_name,
                      postAction: _refreshList,
                      successText: "Rejected company successfully",
                    });
                  },
                },
              ]
            : [
                {
                  key: "info",
                  icon: ["fal", "info"],
                  title: "Details",
                  link: path.tenant_details.replace(":id", data.id),
                },
                {
                  key: "divider",
                },
                {
                  key: "Approve",
                  icon: ["fal", "check-square"],
                  title: "Approve",
                  disabled: value.is_active,
                  onClick: () => {
                    showPatchModal({
                      url: `${url.COMPANY}${value.id}/activate/`,
                      title: "Approve company",
                      content: `Are you sure you want to approve`,
                      message404: "Approve company failed",
                      name: data.company_name,
                      postAction: _refreshList,
                      successText: "Approved company successfully",
                    });
                  },
                },
                {
                  key: "Delete",
                  icon: ["fal", "trash-alt"],
                  title: "Delete",
                  disabled: value.is_active,
                  onClick: () => {
                    showDeleteModal({
                      url: `${url.COMPANY}${value.id}/`,
                      title: "Delete company",
                      content: `Are you sure you want to delete`,
                      message404: "Delete company failed",
                      name: data.company_name,
                      postAction: _refreshList,
                      successText: "Delete company successfully",
                    });
                  },
                },
              ];
          return <SNButtonGroup dropdown={actions} />;
        },
      },
    ];

    return (
        <ProTable<CompanyDataApi, { search: any }>
          sticky={true}
          actionRef={listRef}
          request={async (
            { pageSize, current },
            sort
          ): Promise<RequestData<CompanyDataApi>> => {
            const sortKey = Object.keys(sort).length
              ? Object.keys(sort)[0]
              : "";
            const sortParams = sortKey.length
              ? `&ordering=${sort[sortKey] === "descend" ? "-" : ""}${sortKey}`
              : "";
            const locationParams = qs.stringify(
              paramsToObject(location.search)
            );

            const urlRequest = `${url.COMPANY}?limit=${pageSize}&offset=${
              (current - 1) * pageSize
            }${sortParams}${search ? `&search=${search}` : ""}${
              locationParams ? `&${locationParams}` : ""
            }`;
            const res = await api.get(urlRequest);

            return {
              data: res.data.results,
              success: true,
              total: res.data.count,
            };
          }}
          params={location.search}
          headerTitle="All companies"
          search={false}
          toolBarRender={() => [            
            <SNButton
              key="plus-button"
              title="Create company"
              iconcolor={blue.primary}
              icon="plus"
              onClick={() => history.push(path.register_tenant)}
            />,
            
            <CompanyFilter key="filter" />
          ]}
          options={{
            setting: false,
            fullScreen: false,
            density: false,
          }}
          size={"small"}
          pagination={{ pageSize: 100, showQuickJumper: true }}
          rowKey={(key) => key.id}
          columns={columns}
          dateFormatter="string"
        />
    );
  };

  return <SNPanel view="list">{_renderList()}</SNPanel>;
};

export default CompaniesList;
